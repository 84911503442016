import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UIRouterModule } from '@uirouter/angular';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { routes } from './common-header.routes';
import { AuthButtonsComponent } from './components/auth-buttons/auth-buttons.component';
import { CommonHeaderComponent } from './components/common-header/common-header.component';
import { CompanyAddComponent } from './components/company-add/company-add.component';
import { CompanyBannerComponent } from './components/company-banner/company-banner.component';
import { CompanyButtonsComponent } from './components/company-buttons/company-buttons.component';
import { CompanyDetailsComponent } from './components/company-details/company-details.component';
import { CompanyFieldsComponent } from './components/company-fields/company-fields.component';
import { CompanyIcpModalComponent } from './components/company-icp-modal/company-icp-modal.component';
import { CompanyLicenseItemComponent } from './components/company-license-item/company-license-item.component';
import { CompanyLicenseTotalsComponent } from './components/company-license-totals/company-license-totals.component';
import { CompanyLicensesComponent } from './components/company-licenses/company-licenses.component';
import { CompanyRoleModalComponent } from './components/company-role-modal/company-role-modal.component';
import { HelpWidgetButtonComponent } from './components/help-widget-button/help-widget-button.component';
import { MoveCompanyComponent } from './components/move-company/move-company.component';
import { PlanBannerComponent } from './components/plan-banner/plan-banner.component';
import { SafeDeleteModalComponent } from './components/safe-delete-modal/safe-delete-modal.component';
import { SelectCompanyComponent } from './components/select-company/select-company.component';
import { UserAddComponent } from './components/user-add/user-add.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { UserFieldsComponent } from './components/user-fields/user-fields.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { CommonHeaderHeightDirective } from './directives/common-header-height.directive';
import { SignOutButtonDirective } from './directives/sign-out-button.directive';
import { FullAddressPipe } from './pipes/full-address.pipe';
import { CompanyIcpService } from './services/company-icp.service';
import { ViewportComponent } from './viewport/viewport.component';

@NgModule({
  declarations: [
    CommonHeaderHeightDirective,
    MoveCompanyComponent,
    SelectCompanyComponent,
    FullAddressPipe,
    CompanyFieldsComponent,
    SafeDeleteModalComponent,
    UserFieldsComponent,
    UserListComponent,
    CompanyBannerComponent,
    CompanyButtonsComponent,
    SignOutButtonDirective,
    PlanBannerComponent,
    HelpWidgetButtonComponent,
    CompanyIcpModalComponent,
    CompanyRoleModalComponent,
    ViewportComponent,
    CompanyAddComponent,
    CompanyDetailsComponent,
    UserAddComponent,
    UserDetailsComponent,
    CompanyLicensesComponent,
    CompanyLicenseItemComponent,
    CompanyLicenseTotalsComponent,
    CommonHeaderComponent,
    AuthButtonsComponent
  ],
  imports: [
    UIRouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ComponentsModule,
    SharedModule,
    PopoverModule.forRoot(),
    MatSlideToggleModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatBottomSheetModule,
    MatListModule,
    MatButtonModule,
    MatMenuModule
  ],
  exports: [
    CommonHeaderHeightDirective,
    SignOutButtonDirective
  ]
})
export class CommonHeaderModule {
  static entryComponents = [ CompanyBannerComponent, CompanyButtonsComponent, PlanBannerComponent,
    HelpWidgetButtonComponent,
    SignOutButtonDirective
  ];
  static providers = [ CompanyIcpService ];
}
