import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';

import { TransitionService } from '@uirouter/angular';

import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { RvshareAppDetectionService } from 'src/app/screen-sharing/services/rvshare-app-detection.service';
import { environment } from 'src/environments/environment';
import { CompanyIcpService } from '../../services/company-icp.service';
import { checkCookies } from '../../services/cookie-tester.service';

@Component({
  selector: 'common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent implements OnInit {

  navCollapsed = true;
  ENV_NAME = environment.ENV_NAME;

  navOptions = [];
  navSelected = 'apps.editor.home';
  hideCommonHeader = true;

  cookieEnabled = true;
  bottomSheetRef: MatBottomSheetRef<any>;

  showMenuItem = (menuItem) => true;

  get isScreenShareApp(): any {
    return this.rvShareAppDetection.isScreenShareApp();
  }

  get isRiseVisionUser(): any {
    return this.userStateService.isRiseVisionUser();
  }

  get isAssignedPublisher(): any {
    return this.userStateService.isAssignedPublisher();
  }

  get isModerator(): any {
    return this.userStateService.isModerator();
  }

  constructor(private transitionService: TransitionService,
    private changeDetectorRef: ChangeDetectorRef,
    public userStateService: UserStateService,
    private companyIcpService: CompanyIcpService,
    private rvShareAppDetection: RvshareAppDetectionService,
    private featuresService: FeaturesService,
    public actionSheet: MatBottomSheet,
  ) {
  }

  _refreshNavOptions() {
    this.navOptions = [{
      title: 'Presentations',
      link: 'apps.editor.home',
      states: [
        'apps.editor.home',
        'apps.editor.list',
        'apps.editor.workspace.artboard',
        'apps.editor.workspace.htmleditor',
        'apps.editor.templates.edit'
      ]
    }, {
      title: 'Schedules',
      link: 'apps.schedules.home',
      states: [
        'apps.schedules.home',
        'apps.schedules.list',
        'apps.schedules.details',
        'apps.schedules.add'
      ]
    }, {
      title: 'Displays',
      link: 'apps.displays.home',
      states: [
        'apps.displays.home',
        'apps.displays.list',
        'apps.displays.add',
        'apps.displays.activate',
        'apps.displays.details'
      ],
      restricted: true
    }, {
      title: 'Screen Share',
      link: 'apps.screen-sharing.moderator-join',
      states: [
        'apps.screen-sharing.moderator-join',
        'apps.screen-sharing.moderator-room'
      ],
      moderator: true
    }, {
      title: 'Storage',
      link: 'apps.storage.home',
      states: ['apps.storage.home'],
      restricted: true
    }];

    this.changeDetectorRef.detectChanges();
  }

  isFeatureAvailable = this.featuresService.isFeatureAvailable.bind(this.featuresService);

  upgradePlan() {
    this.featuresService.showUpgradePlanModal('screen_sharing');
  }

  ngOnInit(): void {
    this.companyIcpService.init();

    if (!checkCookies()) {
      this.cookieEnabled = false;
    }

    this.transitionService.onSuccess({}, (transition) => {
      const toState = transition.to().name;

      this.navSelected = toState;
      this.hideCommonHeader =
        toState.indexOf('apps.auth') !== -1 ||
        toState.indexOf('apps.screen-sharing.home') !== -1 ||
        toState.indexOf('apps.billing.invoice') !== -1 ||
        toState.indexOf('apps.billing.unpaid') !== -1;
      this.showMenuItem = (menuItem) => {
        return menuItem.restricted ?
          !this.isAssignedPublisher
          : true;
      };

      if (!this.hideCommonHeader) {
        this._refreshNavOptions();
      }
    });
  }

  protected openMobileMenu(template: TemplateRef<any>) {
    this.bottomSheetRef = this.actionSheet.open(template, { panelClass: 'auth-buttons-action-sheet' });
  }

}